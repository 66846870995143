<template>
  <div class="container-fluid h-100">
    <div class="row p-1 mt-1 mx-1 audio-player h-100">
      <div class="col-12 p-1 audio-player-content" style="max-height: 80%;">
        <div class="row justify-content-start h-100">
          <div class="col-sm-12 col-md-10 offset-md-1" style="max-height: 40%;">
            <div class="row justify-content-start">
              <div class="col-3 px-0">
                <div v-if="album" class="embed-responsive embed-responsive-1by1">
                  <img :src="album.poster | normalizeImg('thumb', 'SD', 'SD')" class="embed-responsive-item">
                </div>
              </div>
              <div class="col-9">
                <div class="h-100 row flex-column justify-content-between ">
                  <div class="col-auto">
                    <div v-if="album">
                      <div class="mt-2">
                        <span v-if="album.itype === 'section_album'"><h5>Album</h5></span>
                        <span v-if="album.itype === 'section_playlist'"><h5>Playlist</h5></span>
                      </div>
                      <div class="mt-3">
                        <h3 class="d-inline-block">
                          <b>{{ album.title }}</b>
                        </h3>
                        <span v-if="album.year" class="ml-3">
                          <span class="text-light-gray">{{ album.year }}</span>
                        </span>
                      </div>
                    </div>
                    <div v-if="album.author" class="mt-3">
                      <span class="text-light-gray">by</span> <h5 class="d-inline-block">
                        {{ album.author }}
                      </h5>
                    </div>
                  </div>
                  <div class="col-auto ">
                    <button type="button"
                            :class="{'active-ctl':playAllMode}"
                            class="btn  player-control mr-2 px-4"
                            @click="playAll()"
                    >
                      <i
                        class="fas fa-play-circle"
                      />
                      Play all
                    </button>
                    <button type="button"
                            :class="{'active-ctl':shuffleMode}"
                            class="btn player-control px-4"
                            @click="shuffleAll()"
                    >
                      <i class="fas fa-random"/> Shuffle
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-10 offset-md-1 mt-5" style="max-height: 60%;">
            <div class="row h-100  overflow-auto">
              <div v-for="(audioTrack, index) in playlist" :key="`k${index}`" class="col-12">
                <div class="playlist-track row w-100 py-3 h-100 overflow-auto"
                     :class="{'active-track': audioTrack === activeTrack}"
                >
                  <div class="col-auto flex-grow-0 track-number px-0">
                    {{ index + 1 }}.
                  </div>
                  <div class="col-1 track-control px-2" :class="{'px-4': audioTrack === activeTrack}">
                    <i v-show="!activeTrack || (audioTrack.id !== activeTrack.id)" class="fas fa-play"
                       @click="play(audioTrack)"
                    />
                    <i v-show="(activeTrack && audioTrack.id === activeTrack.id) && !state.playing" class="fas fa-play"
                       @click="play(audioTrack)"
                    />
                    <i v-show="activeTrack && audioTrack.id === activeTrack.id && state.playing" class="fas fa-pause"
                       @click="pause(audioTrack)"
                    />
                  </div>
                  <div class="col-auto flex-grow-1" @click="togglePlayPause(audioTrack)">
                    {{ audioTrack.title }}
                  </div>
                  <div class="col-auto">
                    {{ audioTrack.duration | duration }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="audio-player-control-tag col-12 p-1 h-auto">
        <audio id="audio-player-tag" :title="activeTrack ? activeTrack.title : 'Play Audio'" class="w-100" controls
               controlsList="nodownload"
               src=""
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { amsClient } from "@/service/ams";

  export default {
    name: "AudioPlayer",
    props: {
      album: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        activeTrack: null,
        loadingItem: null,
        shuffleMode: false,
        playAllMode: false,
        audioTag: null,
        state: {
          playing: false,
        },
      }
    },
    computed: {
      playlist() {
        return this.album?.playlist || []
      },
    },
    mounted() {
      this.audioTag = document.getElementById('audio-player-tag');
      this.audioTag.addEventListener('playing', () => {
        console.log('[player][playing]');
        this.state.playing = true;
      })
      this.audioTag.addEventListener('pause', () => {
        console.log('[player][playing]');
        this.state.playing = false;
      })
      this.audioTag.addEventListener('ended', () => {
        console.log('[player][ended]');
        this.state.playing = true;
        this.handleMode();
      })
      console.log(this.album, this.audioTag)
    },
    methods: {
      handleMode(force = false) {
        if (this.shuffleMode) {
          let filtered = this.playlist.filter((it) => !this.activeTrack || it.id !== this.activeTrack.id);
          let index = Math.floor(Math.random() * Math.floor(filtered.length));
          this.loadAudio(this.playlist[index]);
        } else if (this.playAllMode) {
          if (force || !this.activeTrack) {
            this.loadAudio(this.playlist[0]);
          } else if (this.activeTrack) {
            let pos = this.playlist.indexOf(this.activeTrack);
            this.loadAudio(this.playlist[pos + 1]);
          }
        }
      },
      async loadAudio(audioItem) {
        this.loadingItem = audioItem;
        let data = {
          media: [],
        }
        if (!audioItem.media) {

        } else {
          data = await amsClient.callAms(audioItem.media, { cache: false })

          if (this.loadingItem !== audioItem) {
            return;
          }
        }

        this.loadingItem = null;
        if (data.media.length && data.media[0].link) {
          audioItem.link = data.media[0].link;
          this.playAudio(audioItem, data.media[0].link);
        } else {
          this.$bus.$emit('toggleAuthPopup', {
            component: 'message',
            forceCloseBtn: true,
            force: true,
            // centerOn: '.media-player-video',
            message: 'Not found audio link!',
            actions: {
              close: 'OK',
            },
          });
          this.isLoading = false;
        }
      },
      playAudio(audioItem, link) {
        this.activeTrack = audioItem;
        this.audioTag.src = link;
        this.audioTag.load()
        this.audioTag.play();
      },
      play(audioItem) {
        if (this.activeTrack !== audioItem) {
          this.loadAudio(audioItem)
        } else if (this.activeTrack) {
          this.audioTag.play()
        }
      },
      pause(audioItem) {
        if (this.activeTrack) {
          this.audioTag.pause()
          this.state.playing = false;
        }
      },
      shuffleAll() {
        this.shuffleMode = true;
        this.playAllMode = false;
        this.handleMode()
      },
      playAll() {
        this.shuffleMode = false;
        this.playAllMode = true;
        this.handleMode(true)
      },
      togglePlayPause(audioItem) {
        if (!this.activeTrack) {
          this.play(audioItem)
        } else if (audioItem !== this.activeTrack) {
          this.play(audioItem)
        } else if (this.state.playing) {
          this.audioTag.pause()
          this.state.playing = false;
        } else {
          this.audioTag.play()
          this.state.playing = true;
        }
      },
    },
  }
</script>

<style lang="scss">
  .audio-player {
    color: $player-color-text;
    //background-color: #00c7ff;
    .text-light-gray {
      color: $player-color-text-light;
    }

    .player-control {
      background-color: $player-control-color;
      border-radius: 5px;

      &.active-ctl {
        background-color: $player-active-ctl-color;
        color: $player-color-text;
        font-weight: bold;
      }
    }

    .playlist-track {
      border-bottom: 1px solid $player-playlist-border-color;
      color: $player-color-text;

      &:hover {
        color: $player-list-hover-color;
        cursor: pointer;
      }

      .track-number {
        //display: inline-block;
        min-width: 30px;
      }

      .track-control {
        //display: inline-block;
      }

      &.active-track {
        color: $player-control-color;
      }

    }
  }
</style>