<template>
  <div v-if="info" class="info">
    <div class="info-header">
      <div class="info-header__logo">
        <img :src="logo">
      </div>
      <h2 class="info-header__name text-overflow">
        {{ name }}
      </h2>
      <span class="info-header__views">
        {{ followers }} followers
      </span>
    </div>
    <h3 class="info-title">
      {{ title }}
    </h3>
    <p class="info-description">
      {{ description }}
    </p>
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import normalizeImg from '@/filters/normalizeImg';

  export default {
    name: 'BannerVideoItemInfo',
    props: {
      link: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        info: null,
      }
    },
    computed: {
      logo() {
        return this.info?.creator?.picture || null;
      },
      name() {
        return this.info?.creator?.name || null;
      },
      followers() {
        return this.info?.creator.followers || null;
      },
      title() {
        return this.info?.title || null;
      },
      description() {
        return this.info?.description || null;
      },
    },
    mounted() {
      amsClient
        .callAms(this.link, { cache: false })
        .then(({ head }) => {
          this.info = head;
        })
        .catch(error => console.warn(error));
    },
    methods: {
      normalizeImg,
    },
  }
</script>

<style lang="scss" scoped>
  .info {
    position: absolute;
    top: 0;
    right: 0;
    width: 26%;
    height: 100%;
    padding: 16px 12px;
    background-color: $header-background-color;
    color: #fff;

    @media (max-width: 680px) {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      height: 100px;
      background: linear-gradient(to bottom, transparent 0%, $header-background-color 50%);
    }
  }

  .info-header {
    position: relative;
    min-height: 34px;
    margin-bottom: 22px;
    padding-left: 40px;

    &__logo {
      position: absolute;
      top: 2px;
      left: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__name {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
    }

    &__views {
      display: block;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .info-title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
  }

  .info-description {
    font-size: 14px;
    line-height: 20px;
  }
</style>