<template>
  <div>
    <div class="player-container h-100 w-100" :class="{'fullscreen-emulate': fullscreenEmulate}">
      <iframe
        id="video_player"
        class="player-container--iframe "
        width="100%"
        height="100%"
        :src="playerLink"
        :name="eventIdentifier + '_video_player'"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        allow="autoplay; encrypted-media"
        lazyload="off"
      />

      <div v-if="!playerLink || itemChanging || isLoading" class="player-container--loader">
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
  import MediaVideo from '@/components/player/video/video';
  import MediaRelated from '@/components/player/media/media-related';
  import contentLanguage from '@/filters/contentLanguage.js';
  import stripHTML from '@/filters/stripHtml.js';
  import { AuthVariableMixin } from '@/plugins/mixin';
  import ShareBtn from '@/components/share-btn/share-btn';
  import SubscriptionLabel from '@/components/subscription-label';
  import { amsClient } from '@/service/ams';
  import rtm from '@/service/realTimeMessages';
  import Loader from '@/components/loader/loader';
  import { PLAYER_EVENTS, PLAYER_METHODS, PM, SHOP_EVENTS, SHOP_METHODS } from 'vimmi-web-utils/cjs/postMessage';
  import Config from '@/service/config';
  import Auth from '@/service/authService';
  import { normDomain } from 'vimmi-web-utils/cjs/normDomain';
  import { normalizeLang } from 'vimmi-web-utils/cjs/normalizeLang';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import { $stats } from '@/plugins/analytics/vue-analytics-plugin';
  import { formatMediaUrl } from '@/utils';
  import { normalizeImg } from '@/filters';
  import { mapGetters, mapState } from 'vuex';
  import WishlistService from '@/store/wishlist';
  import { shuffle } from 'lodash';

  export default {
    name: 'PlayerWithTabs',
    components: {
      MediaVideo,
      MediaRelated,
      ShareBtn,
      SubscriptionLabel,
      Loader,
    },
    mixins: [AuthVariableMixin],
    props: {
      tabs: {
        type: Array,
        default: () => ([]),
      },
      watchLives: {
        type: Boolean,
        default: false,
      },
      aspectRatio: {
        type: String,
        default: null,
      },
      shopLayouts: {
        type: [Object, String],
        default: null,
      },
      floatingComponent: {
        type: Boolean,
        default: false,
      },
      playerId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        rtmSubscribed: [],
        rtmSubscription: {
          ...(this.watchLives ? {
            'started': this.onNewLiveStarted,
            'on_air_now': this.onNewLiveStarted,
            'finished': this.onSomeLiveFinished,
            // ['started', 'on_air_now', 'finished']
          } : {}),
        },
        subscribed: [],
        pmSubscriptions: {
          [PLAYER_EVENTS.READY]: this.onPlayerReady,
          [PLAYER_EVENTS.LOADEDMETADATA]: this.onPlayerLoadedMetadata,
          [PLAYER_METHODS.FORCE_LOGIN]: this.openAuthPopup,
          [PLAYER_EVENTS.USER_PLAY]: this.onUserClickPlay,
          [PLAYER_EVENTS.USER_RESUME]: this.onUserClickResume,
          [PLAYER_EVENTS.USER_PAUSE]: this.onUserClickPause,
          [PLAYER_EVENTS.TIME_UPDATE]: this.onPlayerTimeUpdate,
          [PLAYER_EVENTS.SEEKED]: this.onPlayerSeeked,
          [PLAYER_EVENTS.SEEKING]: this.onPlayerSeeking,
          [PLAYER_EVENTS.PLAYING]: this.onPlayerPlaying,
          [PLAYER_EVENTS.ENDED]: this.onPlayerEnded,
          [PLAYER_EVENTS.ERROR]: this.onPlayerError,
          [PLAYER_EVENTS.AREA_CLICK]: this.onUserAreaClick,
          [SHOP_EVENTS.USER_CLICK_ON_TAB]: this.onUserClickTab,
          [SHOP_EVENTS.USER_CLICK_PREV]: this.onUserClickPrev,
          [SHOP_EVENTS.USER_CLICK_NEXT]: this.onUserClickNext,
          [SHOP_EVENTS.APP_STATS]: this.onPlayerStats,
          [SHOP_METHODS.OPEN_AUTH_POPUP]: this.openAuthPopup,
          [SHOP_METHODS.SHARE_ITEM]: this.handleShare,
          [SHOP_METHODS.OPEN_STORE]: this.openStorePage,
          [SHOP_METHODS.OPEN_PRODUCT_IFRAME]: this.onLiveProductClick,
          [SHOP_METHODS.OPEN_PRODUCT_POPUP]: this.onLiveProductClick,
          [SHOP_METHODS.TOGGLE_MUTE]: this.toggleMute,
          [SHOP_METHODS.TOGGLE_LIKE]: this.toggleLike,
          [SHOP_METHODS.TOGGLE_FULLSCREEN]: this.toggleFS,
          // [SHOP_METHODS.CLOSE_PLAYER]': this.closePlayer, // todo:
          // [SHOP_METHODS.CLOSE_FLOATING]: this.closeFloating, // todo:
        },
        isPlayerReady: false,
        isFirstVideoApplied: false,
        isLoading: true,
        itemChanging: false,
        playerUniqId: Math.ceil(Math.random() * 10000000),
        tabsData: {},
        activeTabKey: {},
        playerLink: null,
        fullscreenEmulate: false,
        itemsStats: {},
        currentItem: {},
      };
    },
    computed: {
      ...mapGetters({
        getVideosList: 'wishlist/getVideosList',
        getPlayTemplate: 'chachedPreviewMedia/getPlayTemplate',
        setTemplate: 'chachedPreviewMedia/setPlayTemplate',
      }),
      ...mapState('popup', ['floatingMode']),
      ...mapGetters({
        wishList: 'wishlist/getVideosList',
      }),

      eventIdentifier() {
        if (!!this.playerId) {
          return this.playerId;
        }
        return this.playerUniqId;
      },
    },
    watch: {
      $route() {
        if (this.isPrivate && !this.isAuthForPrivateEvent) {
          this.$store.commit('dataAreasLive/setCurrentLive', null);
          this.$store.commit('player/SET_PLAYER_COORDS', null);
        }
      },
    },
    created() {
      this.subscribeToRTMEvents(this.rtmSubscription);
      Config.loaded(async () => {
        if (this.tabs.length) {
          this.formatPlayerLink();
          this.$nextTick(() => {
            this.subscribeToPlayerEvents(this.pmSubscriptions);
          });
          await this.loadTabsData(this.tabs);
        }
        this.isLoading = false;
      });

    },
    mounted() {
      this.$bus.$on('hideBuyBtn', () => this.successPurchase = true);
      this.$bus.$on('logout', () => this.successPurchase = false);
    },
    beforeDestroy() {
      this.unSubscribePlayerEvents();
      this.unSubscribeRTMEvents();

      /** analytics */
      if (this.stream_time > 1) {
        const options = this.$stats.formatEventDate(this.currentItem);
        $stats.send('watch', { ...options });
        const eventData = this.$stats.collect(this.currentItem.id, 'heartbeat', 'ended', { stream_time: this.stream_time });
        if (eventData) {
          const options = this.$stats.formatEventDate(this.currentItem);
          this.$stats.send('heartbeat', { ...options, ...eventData });
        }
        this.$stats.clearCollectedData(this.currentItem.id, 'heartbeat');
      }
      /** analytics end */
    },
    methods: {
      formatPlayerLink() {
        let playerUrl = Config.get('player.url');
        let options = Object.assign(
          {},
          Config.get('player.options'),
          {
            guest: Auth.get('user')?.guest ? 1 : 0,
            ident: this.eventIdentifier,
          },
          {
            initEmpty: true,
            debug: true,
            font_size: '14px',
            env: Config.get('env'),
            lang: this.$i18n.locale,
            mode: 'l',
            dm: location.hostname,
            pg: location.href.replace(location.origin, ''),
          },
        );
        this.playerLink = formatMediaUrl(playerUrl, options);
      },
      async loadTabsData() {
        const awaiting = [];
        Object.entries(this.tabs).map(([index, tab]) => {
          let call;
          let order = +index + 1;
          if (tab.id === 'live') {
            // it needs to check lives firstly
            order = 0;
            call = this.checkActualEvents(0);
          } else {
            call = amsClient.callAms(tab.link);
          }
          if (call) {
            awaiting.push(call
              .then(data => {
                let items = data.items || [];
                if (tab.id !== 'live') {
                  items = shuffle(items);
                }
                if (items.length) {
                  this.tabsData = {
                    ...this.tabsData,
                    [tab.id]: {
                      order: order,
                      items,
                      tab,
                    },
                  };
                  if (!this.isFirstVideoApplied) {
                    this.isFirstVideoApplied = true;
                    this.initFirstVideo();
                  }
                } else {
                  delete this.tabsData[tab.id];
                }
                return true;
              }).catch((e) => {
                console.warn(e);
                delete this.tabsData[tab.id];
              }));
          } else {
            delete this.tabsData[tab.id];
          }
        });
        return Promise.all(awaiting).then(() => {
          return true;
        });
      },
      subscribeToPlayerEvents(subscriptions) {
        Object.keys(subscriptions).forEach(key => {
          this.subscribed.push(PM.inst().target(this.eventIdentifier + '_video_player').on(key, subscriptions[key]));
        });
      },
      subscribeToRTMEvents(subscriptions) {
        Object.keys(subscriptions).forEach(key => {
          this.rtmSubscribed.push(rtm.on(key, subscriptions[key]));
        });
      },
      unSubscribeRTMEvents() {
        rtm.off(this.rtmSubscribed);
        this.rtmSubscribed = [];
      },
      unSubscribePlayerEvents() {
        PM.inst().off(this.subscribed);
        this.subscribed = [];
      },
      onChangedUserState(data) {
        Config.loaded(async () => {
          await this.updatePlayerConf();
        });
      },

      async onSync(data) {
        if (data && data.afterLogin) {
          return;
        }

        await this.updatePlayerConf();
      },
      onUserClickPlay() {
        //todo: method startPlayerVideo in video.vue
      },
      onUserClickResume(data) {
        if (data.userAction) {
          this.$stats.collect(this.currentItem.id, 'watch', 'resume', data);
        }
        //todo: method startPlayerVideo in video.vue
      },
      onUserClickPause(data) {
        if (data.userAction) {
          this.$stats.collect(this.currentItem.id, 'watch', 'pause', data);
        }
      },
      openAuthPopup(data) {
        const message = data?.message || '';
        this.$bus.$emit('toggleAuthPopup', {
          component: 'login',
          message,
          force: true,
        });
      },
      handleShare(data) {
        let link = data.link; /*|| `/s/${data.creatorSlug}`;*/
        if (!link) {
          if (data.itype === 'item_mov_vod_shoprz' || data.itype === 'item_epg_shop_event') {
            link = `/s/${data.creatorSlug}/v/${data.slug}/`;
          } else {
            link = `/s/${data.creatorSlug}`;
          }
        }

        this.$bus.$emit('ToggleSharingDialog', {
          currentStoreLink: `${this.$location}${link}`,
          currentStoreName: data.creator,
          item: this.currentItem,
        });
      },
      openStorePage(data) {
        if (data.storeName) {
          this.$router.push(`/s/${data.storeName}`);
        }
      },
      onLiveProductClick(data) {
        if (data.item?.is_external && data.link) {
          window.open(data.link, '_blank');
          return;
        }
        if (data.item) {
          data.item.link = data.link;
          data.item.ref = data.ref;
          this.$bus.$emit('toggleProductPopup', { item: data.item, options: { float: false } });
        }
      },
      toggleMute(data) {
        this.$store.commit('player/SET_MUTED', data.muted);
      },
      toggleFS(data) {
        this.$bus.$emit('togglePlayerFullscreen', data);
        this.fullscreenEmulate = data.emulate && data.state;
      },
      async toggleLike(data) {
        if (Object.keys(this.wishList).includes(data.id)) {
          await WishlistService.removeFromVideosWishlist(data.id);
        } else {
          await WishlistService.addToVideoWishlist(data.id);
        }
        this.send(SHOP_EVENTS.FAVORITE_TOGGLED, {
          isWishList: Object.keys(this.wishList).includes(data.id),
        });
      },
      onPlayerStats(data) {
        if (data.event) {
          $stats.send(data.event, {
            ...data,
            channel_id: this.currentItem?.creator?.id,
            store_id: 'verb',
          });
        }
      },
      onPlayerTimeUpdate(data) {
        //todo

        this.$stats.collect(this.currentItem.id, 'watch', 'timeupdate', event);

        const eventData = this.$stats.collect(this.currentItem.id, 'heartbeat', 'timeupdate', data);
        if (eventData) {
          const options = this.$stats.formatEventDate(this.currentItem);
          this.$stats.send('heartbeat', { ...options, ...eventData });
        }

        this.itemsStats[this.currentItem.id] = this.itemsStats[this.currentItem.id] || { stream_time: 0 };
        this.itemsStats[this.currentItem.id].stream_time = data.stream_time;
      },
      onPlayerSeeked(data) {
        this.$stats.collect(this.currentItem.id, 'watch', 'seek', data);
      },
      onPlayerSeeking(data) {
        this.$stats.collect(this.currentItem.id, 'watch', 'seeking', data);
      },
      onPlayerPlaying(data) {
        this.$stats.collect(this.currentItem.id, 'watch', 'seeking', data);
      },
      onPlayerEnded(data) {
        this.$stats.collect(this.currentItem.id, 'watch', 'ended', data);
        const options = this.$stats.formatEventDate(this.currentItem);
        const eventData = this.$stats.collect(this.currentItem.id, 'heartbeat', 'ended', data);
        if (eventData) {
          this.$stats.send('heartbeat', { ...options, ...eventData });
        }
        this.$stats.clearCollectedData(this.currentItem.id, 'heartbeat');

        $stats.send('watch', { ...options });
      },
      onPlayerError(data) {

      },
      onUserAreaClick(data) {

      },
      async onUserClickTab({ id }) {
        console.log('[onUserClickTab]', id);
        if (this.itemChanging) {
          return;
        }
        if (this.tabsData[id]) {
          this.itemChanging = true;
          this.activeTabKey = id;
          await this.playItem(this.tabsData[this.activeTabKey].items[0]);
          this.itemChanging = false;
        }
      },
      async onUserClickPrev(data) {
        console.log('[onUserClickPrev]', data);
        if (this.itemChanging) {
          return;
        }
        this.itemChanging = true;
        const tabsKeys = Object.keys(this.tabsData);
        const activeTabIndex = tabsKeys.findIndex((key) => key === this.activeTabKey);
        const index = this.tabsData[this.activeTabKey].items.findIndex((item) => {
          return this.currentItem.id === item.id;
        });
        if (this.tabsData[this.activeTabKey].items[index - 1]) {
          await this.playItem(this.tabsData[this.activeTabKey].items[index - 1]);
        } else if (this.tabsData[tabsKeys[activeTabIndex - 1]]) {
          this.activeTabKey = tabsKeys[activeTabIndex - 1];
          await this.playItem(this.tabsData[this.activeTabKey].items[this.tabsData[this.activeTabKey].items.length - 1]);
        } else {
          this.activeTabKey = tabsKeys[tabsKeys.length - 1];
          await this.playItem(this.tabsData[this.activeTabKey].items[this.tabsData[this.activeTabKey].items.length - 1]);
        }
        this.itemChanging = false;
      },
      async onUserClickNext(data) {
        console.log('[onUserClickNext]', data);
        if (this.itemChanging) {
          return;
        }
        this.itemChanging = true;
        const tabsKeys = Object.keys(this.tabsData);
        const activeTabIndex = tabsKeys.findIndex((key) => key === this.activeTabKey);
        const index = this.tabsData[this.activeTabKey].items.findIndex((item) => {
          return this.currentItem.id === item.id;
        });
        if (this.tabsData[this.activeTabKey].items[index + 1]) {
          await this.playItem(this.tabsData[this.activeTabKey].items[index + 1]);
        } else if (this.tabsData[tabsKeys[activeTabIndex + 1]]) {
          this.activeTabKey = tabsKeys[activeTabIndex + 1];
          await this.playItem(this.tabsData[this.activeTabKey].items[0]);
        } else {
          this.activeTabKey = tabsKeys[0];
          await this.playItem(this.tabsData[this.activeTabKey].items[0]);
        }
        this.itemChanging = false;
      },
      onPlayerLoadedMetadata() {

        this.$stats.collect(this.currentItem.id, 'heartbeat', 'ready', { stream_time: 0 });
      },

      isVideoRestricted(item) {
        return new Promise((resolve) => {
          let age =
            item && item.age_restriction
              ? item.age_restriction !== 'AP' && item.age_restriction !== 'None'
                ? parseInt(item.age_restriction)
                : item.age_restriction !== 'None'
                  ? 0
                  : 0
              : 0;
          if (!this.isAuth || (this.isGuest && item.noguest)) {
            resolve('RestrictedForUnknow');
          } else if (
            amsClient.isContentPremium(item) &&
            !amsClient.canPlayPremium(item)
          ) {
            resolve('RestrictedForNotFree');
          } else if (
            amsClient.isContentFreemium(item) &&
            !amsClient.canPlayFreemium(item)
          ) {
            resolve('RestrictedForNotFree');
          } else {
            this.isShowRestrictedMessage = false;
            resolve(null);
          }
        });
      },
      async prepareItem(item) {

        const isLive = ['item_live_virtual', 'item_live', 'item_epg_shop_event'].indexOf(item.itype) !== -1;
        const options = {
          playerOptions: {
            loop: !isLive,
            autoplay: true,
            forceAutoplayMuted: true,
            muted: !!this.floatingMode,
          },
          extraData: {},
        };


        if (!item.media) {
          item.media = `/play/${item.id}/`;
        }

        if (this.activeTabKey) {
          item.portal_tab_id = this.activeTabKey;
        }

        options.isLive = isLive;
        options.media_id = item.id;
        options.itype = item.itype;

        let screen = this.$stats.get('current.screen') || {};
        options.extraData = {
          poster: normalizeImg(item.backdrop || item.poster, 'image'),
          title: item.title,
          screen_id: screen?.id || undefined,
          screen_type: screen && this.$stats.utils.normaliseScreenType(screen.link),
        };

        options.restricted = await this.isVideoRestricted(item);

        /*options.playerOptions.bigButton = opt.bigButton = this.isProductVod ? this.item.playerOptions.showPlayButton : true;
        options.playerOptions.mainPage = opt.mainPage === true;
        options.playerOptions.controls = this.isProductVod ? this.item.playerOptions.controls : !options.isLive;
        options.playerOptions.muted = true;*/

        /*if (this.item.playerOptions) {
          options.playerOptions.startPosition = this.item.playerOptions.startPosition;
        } else {
          if (opt.startPosition) {
            options.playerOptions.startPosition = opt.startPosition;
          } else {
            options.playerOptions.startPosition = 0;
          }
        }*/

        // this.$logger && this.$logger.info('loaded video playOptions', options);
        // this.currentOptions = options;

        let mediaUrl = null;

        if (item.itype === 'item_mov_vod_shoprz') {
          if (item.mid && !item.recorded) {
            if (!this.getPlayTemplate?.info) {
              if (this.setTemplate) {
                await this.setTemplate();
              }
            }
            if (this.getPlayTemplate?.info) {
              mediaUrl = this.getPlayTemplate.info.vod.replace(/{MID}/gi, item.mid);
            }
          } else if (item.recorded && item.media_url) {
            mediaUrl = item.media_url;
          }
        }
        if (item.itype === 'item_epg_shop_event') {
          mediaUrl = item.media;
        }

        item.mediaUrl = mediaUrl;
        return { item, options };
      },
      async checkActualEvents(attempts = 0) {
        const { items } = await amsClient.callAms('/on_air/', {
          cache: false,
          query: {
            ...(this.$eventsTimestamp && { t: this.$eventsTimestamp }),
          },
        });

        if (!items.length && attempts) {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          attempts -= 1;
          return await this.checkActualEvents(attempts);
        }
        return { items };
      },
      async onNewLiveStarted(data) {
        if (data.op === 'on_air_now' && !data.msg.events.length) {
          return;
        }
        this.onSomeLiveFinished();
      },
      async onSomeLiveFinished() {
        const liveTab = this.tabs.find((tab) => {
          return tab.id === 'live';
        });
        if (liveTab) {
          const { items } = await this.checkActualEvents(5);
          if (items.length) {
            this.tabsData['live'] = {
              order: 1,
              items: items,
              tab: liveTab,
            };
            this.send(SHOP_METHODS.SET_TABS, {
              tabs: this.tabsData,
            });
          } else {
            delete this.tabsData['live'];
            this.send(SHOP_METHODS.SET_TABS, {
              tabs: this.tabsData,
            });
          }

          if (this.currentItem.on_air && !items.find((item) => this.currentItem.id === item.id)) {
            this.itemChanging = true;
            if (items.length) {
              await this.playItem(items[0]);
            } else {
              this.activeTabKey = Object.keys(this.tabsData)[0];
              if (this.activeTabKey) {
                await this.playItem(this.tabsData[this.activeTabKey].items[0]);
              }
            }
            this.itemChanging = false;
          }
        }
      },
      onPlayerReady() {
        Config.loaded(async () => {
          this.isPlayerReady = true;
          if (Object.keys(this.tabsData).length) {
            this.initFirstVideo();
          }
        });
      },
      async initFirstVideo() {
        this.send(SHOP_METHODS.SET_TABS, {
          tabs: this.tabsData,
        });
        this.activeTabKey = Object.keys(this.tabsData)[0];
        await this.playItem(this.tabsData[this.activeTabKey].items[0]);
        this.$stats.collect(this.currentItem.id, 'heartbeat', 'ready', { stream_time: 0 });
      },
      async playItem(itemToPlay) {
        const { item, options } = await this.prepareItem(itemToPlay);
        await this.updatePlayerConf(item);

        if (options.isLive) {
          rtm.init(item.id, null, {
            cleanSubscribes: false,
          });
        } else if (rtm.roomId !== 'lobby') {
          rtm.init('lobby', null, {
            cleanSubscribes: false,
          });
        }

        console.log('[send][SHOP_METHODS.PLAY_EVENT]', {
          item,
          options,
        });
        this.currentItem = item;
        this.send(SHOP_METHODS.PLAY_EVENT, {
          item,
          options,
        });
      },
      async updatePlayerConf(item = null, extraConf = {}) {
        const chatConfig = amsClient.get('conf.chat');

        const handledItem = item || this.currentItem;
        let chat = null;

        if (handledItem.chat_id) {
          chat = {
            chat_id: handledItem.chat_id,
            chat_url: handledItem.chat_url,
            readonly: handledItem.is_chat_enabled === undefined || handledItem.is_chat_enabled === null
              ? false
              : !handledItem.is_chat_enabled,
          };
        } else if (handledItem.itype === 'item_mov_vod_shoprz') {
          const { head } = await amsClient.callAms(`/get_item/${handledItem.id}/`);

          if (!head.event || !head.event?.chat_id) {
            return;
          }

          chat = {
            chat_id: head.event.chat_id,
            chat_url: head.event.chat_url,
            readonly: head.is_chat_enabled === undefined || head.is_chat_enabled === null
              ? false
              : !head.is_chat_enabled,
          };
        }

        this.send(PLAYER_METHODS.CONFIGURATION, {
          data: {
            portalUser: Auth.get('user'),
            isPortalUserAuth: this.isFullAuth,
            item: handledItem,
            conf: {
              features: amsClient.get('conf.shoprz_features') || {},
              chatUrl: chatConfig?.chat_backend_url ? normDomain(chatConfig.chat_backend_url) : null,
              portalLink: amsClient.get('conf.portal.portal_link'),
              appId: handledItem?.creator?.id,
              oId: handledItem?.creator?.slug,
              floatMode: false,
            },
            controlsState: {
              toggleFloatPlayerBtn: false,
              toggleFavoriteBtn: false,
            },
            chat: {
              ...chat,
              enabled: true,
            },
            creator: handledItem?.creator || null,
            portal: {
              /** for player module, start*/
              public_app_key: Config.get('auth.ams.public_app_key'),
              private_id: Auth.get('user.privateId'),
              concurrency: Auth.get('concurrency'),
              'Device-Info': amsClient.getAMSDeviceInfo(),
              // browser: Device.info.browser.name,
              // os_version: Device.info.os.version,
              /** for player module, end*/

              app_id: Config.get('app_id'),
              app_info: Config.get('app_info'),
              app_version: Config.get('version'),
              user_lang: normalizeLang(Config.get('activeLang'), 'iso-2'),
              language: Config.get('activeLang'),
              user_id: Auth.get('user.id'),

              classification:
                (amsClient.get('user.classes') || []).join(',') || 'non-ais',
              device_id: Auth.get('authInfo.device_id'),
              device_key: Config.get('device_key'),

              customer: process.env.VUE_APP_CUSTOMER,
              sid: Auth.get('user.sid'),
              restrictedDevices: Auth.getDeviceRestriction(),
              preferred_audio_lang:
                localStore.get('preferred_audio_lang') || this.$i18n.locale,
              server: location.host,
              metadata: Config.get('player.metadata'),

            },
            player_conf: {
              vimmi_copyright: false,
              quality_selector: { reverse: true },
            },
            ...extraConf,
          },
        });
      },
      send(type, data) {
        data = data || {};
        const target = this.getCurrentFrame(this.eventIdentifier + '_video_player');

        PM.inst().send({
          target,
          type: type,
          data: data,
        });
      },
      getCurrentFrame(nameOrId) {
        let el = document.getElementById(nameOrId);
        if (!el) {
          el = document.querySelector('[name="' + nameOrId + '"]');
        }
        return el || null;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .player-container {
    position: relative;

    &--iframe {
      border: none;
    }

    &--loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);

      .loading-container {
        position: relative;
        height: 100%;
      }
    }
  }

  .fullscreen-emulate.player-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 1000;
  }
</style>
