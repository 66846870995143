<template>
  <div v-if="$isStandaloneStore">
    <div v-show="event && !finishedLive" class="channel-event-container">
      <div class="container-fluid" style="max-width: 1440px; overflow: hidden">
        <div
          class="bg-block channel-event-video"
          style="max-width: 1440px; overflow: hidden"
        >
          <div
            ref="eventPlaceholder"
            class="event-placeholder"
            :style="{
              'background-image':
                isBackground && image ? 'url(' + image + ')' : 'none',
            }"
          >
            <!-- <suggestions
              v-if="finishedCurrentLive && !$isStandaloneStore"
              :event="event"
              :items="suggestionsItems"
              :loading="loading"
            /> -->
            <template v-if="!isBackground">
              <svg
                width="36"
                height="37"
                viewBox="0 0 36 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.4925 36.0015H9.45977C2.9126 36.0015 -0.000976562 33.0879 -0.000976562 26.5406V19.5078C-0.000976562 12.9605 2.9126 10.0469 9.45977 10.0469H16.4925C23.0397 10.0469 25.9533 12.9605 25.9533 19.5078V26.5406C25.9533 33.0879 23.0397 36.0015 16.4925 36.0015ZM9.45977 12.5586C4.26892 12.5586 2.51073 14.3168 2.51073 19.5078V26.5406C2.51073 31.7315 4.26892 33.4898 9.45977 33.4898H16.4925C21.6834 33.4898 23.4416 31.7315 23.4416 26.5406V19.5078C23.4416 14.3168 21.6834 12.5586 16.4925 12.5586H9.45977Z"
                  fill="white"
                />
                <path
                  d="M26.5394 25.9546H24.6975C24.011 25.9546 23.4417 25.3853 23.4417 24.6988V19.5078C23.4417 14.3169 21.6835 12.5587 16.4926 12.5587H11.3018C10.6152 12.5587 10.0459 11.9894 10.0459 11.3028V9.46088C10.0459 2.91362 12.9595 0 19.5066 0H26.5394C33.0866 0 36.0002 2.91362 36.0002 9.46088V16.4937C36.0002 23.041 33.0866 25.9546 26.5394 25.9546ZM25.9534 23.4429H26.5394C31.7303 23.4429 33.4885 21.6847 33.4885 16.4937V9.46088C33.4885 4.26996 31.7303 2.51174 26.5394 2.51174H19.5066C14.3158 2.51174 12.5576 4.26996 12.5576 9.46088V10.047H16.4926C23.0398 10.047 25.9534 12.9606 25.9534 19.5078V23.4429Z"
                  fill="white"
                />
              </svg>

              Playing picture-in-picture
            </template>
          </div>
        </div>
      </div>

      <div v-if="actualEvent" class="container-fluid">
        <div class="container-fluid bg-block channel-event-description">
          <div class="channel-event-description__title">
            <div class="live-title font-weight-bold">
              {{ actualEvent.title }}
            </div>
            <pre v-if="actualEvent.description" class="mt-2 live-description">
              {{ (actualEvent.description || '').trim() }}
            </pre>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div v-show="event" class="channel-event-container">
      <div class="container-fluid" style="max-width: 1440px; overflow: hidden">
        <div
          class="bg-block channel-event-video"
          style="max-width: 1440px; overflow: hidden"
        >
          <div
            ref="eventPlaceholder"
            class="event-placeholder"
            :style="{
              'background-image':
                isBackground && image ? 'url(' + image + ')' : 'none',
            }"
          >
            <suggestions
              v-if="finishedLive"
              :event="event"
              :items="suggestionsItems"
              :loading="loading"
            />
            <template v-if="!isBackground">
              <svg
                width="36"
                height="37"
                viewBox="0 0 36 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.4925 36.0015H9.45977C2.9126 36.0015 -0.000976562 33.0879 -0.000976562 26.5406V19.5078C-0.000976562 12.9605 2.9126 10.0469 9.45977 10.0469H16.4925C23.0397 10.0469 25.9533 12.9605 25.9533 19.5078V26.5406C25.9533 33.0879 23.0397 36.0015 16.4925 36.0015ZM9.45977 12.5586C4.26892 12.5586 2.51073 14.3168 2.51073 19.5078V26.5406C2.51073 31.7315 4.26892 33.4898 9.45977 33.4898H16.4925C21.6834 33.4898 23.4416 31.7315 23.4416 26.5406V19.5078C23.4416 14.3168 21.6834 12.5586 16.4925 12.5586H9.45977Z"
                  fill="white"
                />
                <path
                  d="M26.5394 25.9546H24.6975C24.011 25.9546 23.4417 25.3853 23.4417 24.6988V19.5078C23.4417 14.3169 21.6835 12.5587 16.4926 12.5587H11.3018C10.6152 12.5587 10.0459 11.9894 10.0459 11.3028V9.46088C10.0459 2.91362 12.9595 0 19.5066 0H26.5394C33.0866 0 36.0002 2.91362 36.0002 9.46088V16.4937C36.0002 23.041 33.0866 25.9546 26.5394 25.9546ZM25.9534 23.4429H26.5394C31.7303 23.4429 33.4885 21.6847 33.4885 16.4937V9.46088C33.4885 4.26996 31.7303 2.51174 26.5394 2.51174H19.5066C14.3158 2.51174 12.5576 4.26996 12.5576 9.46088V10.047H16.4926C23.0398 10.047 25.9534 12.9606 25.9534 19.5078V23.4429Z"
                  fill="white"
                />
              </svg>

              Playing picture-in-picture
            </template>
          </div>
        </div>
      </div>

      <div v-if="actualEvent" class="container-fluid">
        <div class="container-fluid bg-block channel-event-description">
          <div class="channel-event-description__title">
            <div class="live-title font-weight-bold">
              {{ actualEvent.title }}
            </div>
            <pre v-if="actualEvent.description" class="mt-2 live-description">
              {{ (actualEvent.description || '').trim() }}
            </pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { FollowMixin } from '@/plugins/mixin';
  import device from '@/service/device-service';
  import normalizeImg from '@/filters/normalizeImg';
  import Suggestions from '../../features/suggestions/suggestions.vue';
  import { amsClient } from 'src/service/ams';
  import { PM, SHOP_METHODS } from 'vimmi-web-utils/cjs/postMessage';

  export default {
    name: 'ChannelEvent',
    components: {
      Suggestions,
    },
    mixins: [FollowMixin],
    props: {
      area: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        startCoords: null,
        elementCoords: null,
        event: null,
        loading: true,
        finishedLive: false,
        suggestionsItems: {
          products: [],
          videos: [],
          lives: [],
        },
      };
    },
    computed: {
      screenType() {
        return this.$store.getters['popup/screenType'];
      },
      isLiveFloating() {
        return this.$store.getters['popup/isLiveFloating'];
      },
      isVodFloating() {
        return this.$store.getters['popup/isVodFloating'];
      },
      actualEvent() {
        return this.event?.actualEvent;
      },
      image() {
        if (!this.actualEvent) {
          return null;
        }

        const imgPath = this.isMobile
          ? this.actualEvent.poster
          : this.actualEvent.thumbnail;

        return this.normalizeImg(imgPath, 'thumb', 'HD', 'SD');
      },
      finishedCurrentLive() {
        if (!this.event) {
          return null;
        }
        const finishedCurrentLive = this.$store.getters[
          'dataAreasLive/finishedCurrentLive'
          ](this.event.actualEvent);

        return finishedCurrentLive?.end;
      },
      isMobile() {
        return device.isAnyMobile();
      },
      isMiniatureIframe() {
        return this.screenType === 'miniature';
      },
      currentLive() {
        return this.$store.getters['dataAreasLive/currentLive'];
      },
      currenSecondLive() {
        return this.$store.getters['dataAreasLive/currenSecondLive'];
      },
      isFirstEventComponentFloating() {
        return this.$store.getters['player/isFirstEventComponentFloating'];
      },

      isSecondEventComponentFloating() {
        return this.$store.getters['player/isSecondEventComponentFloating'];
      },

      isBackground() {
        if (!this.currenSecondLive) {
          return !this.isFirstEventComponentFloating;
        }
        return !this.isSecondEventComponentFloating;
      },
      playingLive() {
        return this.$store.getters['dataAreasLive/playingLive'];
      },
      firstLivePlaing() {
        return this.$store.getters['player/firstLivePlaing'];
      },
      routerToLive() {
        return this.$store.getters['player/routerToLive'];
      },
      isRestore() {
        return this.$store.getters['player/isRestore'];
      },
      isFloatingLive() {
        if (this.isMiniatureIframe && this.isLiveFloating) {
          return (
            this.isFirstEventComponentFloating ||
            this.isSecondEventComponentFloating
          );
        }
        return false;
      },
    },
    watch: {
      isMiniatureIframe(value) {
        if (!value) {
          this.handleMount();
        }
      },
      finishedCurrentLive: {
        handler(value) {
          if (value) {
            this.fetchSuggestionsItems();

            if (this.$isStandaloneStore) {
              this.$bus.$emit('updateTitle', this.event.creator.name);
            }
          }
        },
      },
      area: {
        deep: true,
        handler(value) {
          if (value) {
            this.event = value;
            this.finishedLive = false;

            this.SET_LIVE_TO_STORE();
            this.SET_PAGE_FOR_LIVE();

            this.onMounted();
          }
        },
      },
    },
    created() {
      this.$bus.$off('VideoPlayerPlayAction', this.eventPlayerMethods);

      this.SET_LIVE_TO_STORE();
      this.SET_PAGE_FOR_LIVE();
    },
    mounted() {
      this.fetchSuggestionsVideos();

      this.$bus.$on('VideoPlayerPlay', (currentItem) => {
        if (currentItem?.id !== this.actualEvent?.id) {
          this.$bus.$emit('VideoPlayerPlayAction', 'MUTE_OFF');
        }
      });

      this.$bus.$on('setStyleForLive', this.handleMount);

      if (this.routerToLive) {
        this.$store.commit('player/SET_ROUTER_TO_LIVE', false);
      }

      this.onMounted();
    },
    beforeDestroy() {
      window.parent.removeEventListener('orientationchange', this.handleMount);
      window.parent.removeEventListener('resize', this.handleMount);
      if (this.playingLive) {
        this.$bus.$emit('setMiniplayer');
        this.$bus.$emit('setLiveFLoating');
      }
      if (!this.playingLive && !this.isFloatingLive) {
        this.$store.commit('dataAreasLive/setCurrentLive', null);
      }
      this.$store.commit('player/SET_PAGE_FOR_CURRENT_LIVE', false);
      this.$store.commit('player/SET_PAGE_FOR_SECOND_LIVE', false);
      this.$bus.$off('setStyleForLive', this.handleMount);
    },
    methods: {
      normalizeImg,

      onMounted() {
        const timeout = setTimeout(() => {
          this.handleMount();

          clearTimeout(timeout);
        }, 100);

        window.parent.addEventListener(
          'orientationchange',
          this.resetMiniPlayerPosition,
        );

        window.parent.addEventListener('resize', this.handleMount);

        if (this.isRestore) {
          this.$bus.$emit('handleCloseFloatPlayer');
          this.$store.commit('player/SET_RESTORE', false);
        }

        this.$bus.$on('togglePlayerFullscreen', (data)=>{
          if (this.isMobile) {
            this.handleMount();
          }
        });
        /*PM.inst().on(SHOP_METHODS.TOGGLE_FULLSCREEN, () => {
          // this.$bus.$emit('togglePlayerFullscreen', data);
          if (this.isMobile) {
            this.handleMount();
          }
        });*/
      },
      handleMount() {
        if (!this.event) {
          return;
        }

        const eventPlaceholder = this.$refs.eventPlaceholder;

        if (!eventPlaceholder) {
          return;
        }

        const eventStreem = document.querySelectorAll('.event-streem');
        const headPage = document.querySelector('.custom_channel_profile');

        const posX = eventPlaceholder.getBoundingClientRect().x;

        const height = eventPlaceholder.getBoundingClientRect().height;
        const width = eventPlaceholder.getBoundingClientRect().width;

        console.log('eventStreem: ', eventStreem);

        if (eventStreem.length) {
          const timeout = setTimeout(() => {
            const windowWidth = window.innerWidth;

            const posY =
              windowWidth < 1200
                ? 17
                : headPage.getBoundingClientRect().height + 57;

            eventStreem.forEach((item) => {
              if (item.classList.contains('float-event')) {
                return;
              }
              ;

              item.setAttribute(
                'style',
                `width: ${width}px; height: ${height}px; left: ${posX}px; top: ${posY}px;`,
              );
            });

            clearTimeout(timeout);
          }, 500);
        }
      },
      async fetchSuggestionsItems() {
        await Promise.all([
          this.fetchSuggestionsProducts(),
          this.fetchSuggestionsVideos(),
        ]).then(() => {
          this.loading = false;

          this.$store.commit('dataAreasLive/clearFinishedLives');

          this.finishedLive = true;

          this.$store.commit('dataAreasLive/setDurationChangesTrigger', false);
        });
      },

      async fetchSuggestionsProducts() {
        try {
          const response = await amsClient.callAms(
            `/shop/offer/${this.event.actualEvent.shopoffer}/`,
            {
              cache: false,
            },
          );

          response.items.forEach((element) => {
            const duplicateItem = this.suggestionsItems.products.find(
              (item) => item.id === element.id,
            );

            if (!duplicateItem) {
              this.suggestionsItems.products.push(element);
            }
          });
        } catch (error) {
          console.log(error);
        }
      },
      async fetchSuggestionsVideos() {
        try {
          const response = await amsClient.callAms(`/on_air/`, {
            cache: false,
            query: {
              t: new Date().getTime(),
            },
          });
          this.suggestionsItems.lives = response.items;

          if (!this.suggestionsItems.lives.length) {
            const postlive = await amsClient.callAms(`/screen/postlive/`);
            const res = await amsClient.callAms(postlive.items[0].link);

            this.suggestionsItems.videos = res.items;
          }
        } catch (error) {
        }
      },

      resetMiniPlayerPosition(event) {
        const eventStreem = document.querySelector('.event-streem.float-event');

        if (eventStreem) {
          eventStreem.removeAttribute('style');
        }
      },

      SET_LIVE_TO_STORE() {
        if (!this.area) {
          return;
        }
        this.$store.commit('player/SET_IS_LIVE_DATA', this.area);

        if (!this.currentLive) {
          this.$store.commit('dataAreasLive/setCurrentLive', this.area);

          return;
        }

        if (this.currentLive.id !== this.area.id) {
          this.$store.commit('dataAreasLive/setCurrentLive', null);
          const timeout = setTimeout(() => {
            this.$store.commit('dataAreasLive/setCurrentLive', this.area);

            clearTimeout(timeout);
          }, 0);

          return;
        }
      },

      SET_PAGE_FOR_LIVE() {
        if (this.currentLive?.id === this.area?.id) {
          this.$store.commit('player/SET_PAGE_FOR_CURRENT_LIVE', true);
        }
        if (this.currenSecondLive?.id === this.area?.id) {
          this.$store.commit('player/SET_PAGE_FOR_SECOND_LIVE', true);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../style/resource/mixin';

  .event-placeholder {
    height: 645px;
    background-color: #000;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    row-gap: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1440px;
    overflow: hidden;
  }

  .event-title {
    color: $text-color;
    font-size: 25px;
    font-style: normal;
    font-weight: bold;
    line-height: 29px;
    max-width: calc(100% - 140px);
    padding-bottom: 10px;
  }

  .num-viewers {
    align-items: center;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 100px;
    color: $text-color-white;
    display: flex;
    font-size: 14px;
    height: 30px;
    justify-content: center;
    max-width: 65px;
  }

  .live-title {
    color: $text-color;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
  }

  .live-description {
    color: $text-color;
    font-family: $font-family-base;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    white-space: normal;
  }

  .event-bar {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .follow_btn-wrapper {
    min-width: 120px;
  }

  .follow_btn {
    min-height: 35px !important;
    min-width: 120px !important;
  }

  .channel-event-container {
    display: grid;
    grid-template-columns: 3fr 1fr;

    @media screen and (max-width: 896px) {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: $md-breakpoint) {
      .container-fluid {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
        margin-left: 0;

        &.bg-block {
          border-radius: 0;
          background: #fff;
        }
      }
    }
  }

  .channel-event-video {
    background-position: center;
    background-size: contain;
    background-color: #000;

    @media screen and (max-width: 1000px) {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      padding-bottom: 0 !important;
    }

    @include mobile {
      background-size: cover;
      border-radius: 0;
    }
  }

  .channel-event-description {
    height: 100%;
    padding: 1rem;

    @media screen and (max-width: 1000px) {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      padding: 0.5rem 1rem !important;
    }
  }
</style>
