<template>
  <section class="embla-banner-carousel"
           :class="{'with-player': isShowPlayer, 'is-mobile': isMobile }"
           :style="{ height: wrapperHeight, 'margin-top': isMobile && isShowPlayer ? '-1rem' : 'unset' }"
  >
    <div
      class="embla-banner-carousel--wrapper "
      :style="{width: carouselWidth, height: carouselHeight}"
    >
      <embla-carousel
        ref="slider"
        :options="sliderOptions"
        @autoplay:play="onPlayCarousel"
        @autoplay:stop="onPauseCarousel"
      >
        <template #default="{currentIndex, toggleAutoPlay}">
          <div
            v-for="(slide, index) of carouselItems" :key="`${slide.iid}_${index}`"
            class="embla--container--slide mx-2"
            :style="((isMobile && isIOS) || isSafari) ? {'min-width': bannerMobileWidth + 'px'} : {'aspect-ratio': bannerAspectRatio}"
          >
            <!--            :style=""-->
            <component
              :is="getComponentType(slide)"
              :item="slide"
              :index="index"
              :current-index="currentIndex"
              @click.native="handleClickSlide(toggleAutoPlay, slide)"
              @runAutoPlay="playCarousel(toggleAutoPlay)"
              @pauseAutoPlay="pauseCarousel(toggleAutoPlay)"
            >
              <template slot="title">
                <div v-if="slide.title || slide.subtitle" class="banner-names">
                  <h2 v-if="slide.title" class="banner-names__title">
                    {{ slide | contentLanguage('title') }}
                  </h2>
                  <p v-if="slide.subtitle" class="banner-names__subtitle">
                    {{ slide | contentLanguage('subtitle') }}
                  </p>
                </div>
              </template>
            </component>
          </div>
        </template>
      </embla-carousel>
    </div>
    <div v-if="isShowPlayer" class="embla-banner-carousel--player"
         :style="{'aspect-ratio': calcPlayerAspectRatio, height: playerHeight}">
      <slot name="mediaPlayer" />
    </div>

  </section>
</template>
<script>
  import Config from '@/service/config';
  import BannerLinkItem from './banner-link-item/banner-link-item';
  import BannerProductItem from './banner-product-item/banner-product-item';
  import BannerVideoItem from './banner-video-item/banner-video-item';
  import device from '@/service/device-service';
  import EmblaCarousel from '@/components/embla-carousel/index.vue';
  import { screenMode } from 'vimmi-web-utils/cjs/screenMode';

  export default {
    name: 'BannerCarouselEmbla',
    components: {
      EmblaCarousel,
    },
    props: {
      height: {
        type: Number,
        default: 353,
      },
      area: {
        type: Object,
        required: true,
      },
      isShowPlayer: {
        type: Boolean,
        default: false,
      },
      playerAspectRatio: {
        type: Number,
        default: 16 / 9,
      },
      verticalPlayerAspectRatio: {
        type: Number,
        default: 16 / 9,//9 / 19,
      },
      bannerAspectRatio: {
        type: Number,
        default: 2,
      },
    },
    data() {
      return {
        isMobile: device.isMobile() || window.innerWidth <= 1220,
        isIOS: device.isIOS(),
        isSafari: device.isSafari(),
        screenMode: screenMode(),
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        sliderOptions: {
          loop: true,
          dragFree: false,
          // startIndex: 100,
          autoplay: {
            playOnInit: true,
            // stopOnInteraction: true,
            // stopOnMouseEnter: true,
            // stopOnFocusIn: true,
            delay: 5300,
          },
          dots: true,
        },
      };
    },
    computed: {
      bannerMobileWidth() {
        if (this.isMobile) {
          return this.windowWidth;
        }
        return (this.wrapperHeightRaw * this.bannerAspectRatio);
      },
      calcHeight() {
        return this.windowWidth > 2350 ? 580 : this.windowWidth > 1820 ? 450 : this.windowWidth > 1428 ? 353 : 300;
      },
      calcPlayerAspectRatio() {
        let ratio = this.playerAspectRatio;
        if (this.isMobile) {
          ratio = this.screenMode === 'portrait' ? this.verticalPlayerAspectRatio : this.playerAspectRatio;
          if (this.windowWidth / (this.windowHeight - 100) > ratio) {
            ratio = this.windowWidth / (this.windowHeight - 100);
          }
        }
        return ratio;
      },
      carouselItems() {
        return this.area.items || [];
      },
      carouselWidth() {
        if (this.isShowPlayer && !this.isMobile) {
          let slideWidth = (this.wrapperHeightRaw * this.bannerAspectRatio) + 16;
          let playerWidth = this.wrapperHeightRaw * this.calcPlayerAspectRatio;
          let carouselFullWidth = this.windowWidth - (playerWidth) + (this.windowWidth > 2350 ? 80 : this.windowWidth > 1820 ? 50 : this.windowWidth > 1428 ? 30 : 20);
          let slidesOnPage = this.windowWidth / slideWidth;

          let parts = slidesOnPage % 1;

          let offset = slideWidth * (slidesOnPage < 2 ? 1 - parts : parts);
          return `${carouselFullWidth + offset}px`;
        }
        return '100%';
      },
      carouselHeight() {
        if (this.isMobile) {
          return `calc(100vw / ${this.bannerAspectRatio})`;
        }
        return '100%';
      },
      playerHeight() {
        if (this.isMobile) {
          return `auto`;
        }
        return this.calcHeight + 'px';
      },
      wrapperHeightRaw() {
        if (this.isMobile) {
          return 0;
        }

        return this.calcHeight;
      },
      wrapperHeight() {
        return !this.wrapperHeightRaw ? 'auto' : this.wrapperHeightRaw + 'px';
      },
    },
    created() {
      this.$bus.$on('resizeWindow', this.windowResize);
      this.$bus.$on('handleOriChange', this.onOrientationChange);
    },
    mounted() {
      if (!this.carouselItems.length) {
        this.$emit('empty-segment');
      }
    },
    beforeDestroy() {
      this.$bus.$off('resizeWindow', this.windowResize);
      this.$bus.$off('handleOriChange', this.onOrientationChange);
    },
    methods: {
      onPauseCarousel() {
        // console.log('toggleAutoPlay', 'onPauseCarousel');
      },
      onPlayCarousel() {
        // console.log('toggleAutoPlay', 'onPlayCarousel');
      },
      pauseCarousel(toggleAutoPlay) {
        // console.log('toggleAutoPlay', 'pause');
        toggleAutoPlay('pause');
      },
      playCarousel(toggleAutoPlay) {
        // console.log('toggleAutoPlay', 'run');
        toggleAutoPlay('run');
      },
      getComponentType(item) {
        if (item.itype === 'item_mov_vod_shoprz' || item.itype === 'item_epg_shop_event') {
          return BannerVideoItem;
        }

        if (item.itype === 'shop_product') {
          return BannerProductItem;
        }

        return BannerLinkItem;
      },
      handleClickSlide(toggleAutoPlay, item) {
        this.$gtag('event', 'view_banner', {
          banner_name: item.title,
          banner_image: item.banner,
        });

        const id = item.b_id || item.id;
        let eventData = { banner_id: id };

        setTimeout(() => {
          this.$refs.slider.toggleDotBtnsActive();
          this.playCarousel(toggleAutoPlay)
        }, 300);
        Config.set('temp.context', 'banner');
        // this.$stats.send(
        //   'banner_click',
        //   Object.assign({ item_id: id, banner_target: linkNormalisation(item) }, eventData),
        // );
        // assume that it is link to external site
        if (item.link && item.link.indexOf('http') !== -1) {
          // this.$stats.send(
          //   'external_application_opened',
          //   { ext_app_name: item.link },
          // );
        }
      },
      windowResize() {
        this.isMobile = device.isMobile() || this.windowWidth <= 1220;
        this.screenMode = screenMode();
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      },
      onOrientationChange() {
        setTimeout(() => {
          this.screenMode = screenMode();
        }, 1000);
      },
    },
  };
</script>
<style lang="scss" scoped>


  .embla-banner-carousel {
    position: relative;
    margin-top: 8px;
    margin-bottom: 72px;

    &--wrapper {
      display: flex;
      width: 100%;
      height: auto;
    }

    /*  &--player {
        background-color: rgba(13, 40, 105, 0.66);
      }*/

    &:not(&.is-mobile) {
      .embla-banner-carousel--player {
        position: absolute;
        right: 16px;
      }
    }


    &.with-player {
      display: flex;

      .embla-banner-carousel--wrapper {
        position: relative;
        //margin-right: 20px;
        vertical-align: top;
      }

      &.is-mobile {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 10px;

        .embla-banner-carousel--wrapper {
          width: 100% !important;
        }

        .embla-banner-carousel--player {
          width: 100% !important;
        }
      }
    }

    &.is-mobile {
      margin-top: 0;
      margin-bottom: 40px;
    }

    &::v-deep {
      .banner-item {
        position: relative;
        display: block;
        background-color: $header-background-color;
        cursor: pointer;
        height: 100%;
      }

      .banner-preview {
        height: 100%;
        position: relative;
        padding-top: 42%;

        img,
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-position: center;
          background-size: cover;

          &.is-vertical {
            object-fit: contain;
            background-image: none !important;
            background-color: #000;
          }
        }

        video {
          width: calc(100% - 26%);

          @media (max-width: 680px) {
            width: 100%;
          }
        }
      }

      .banner-names {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 50px;
        padding: 24px;
        left: 0;
        right: 0;
        bottom: 0;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: block;
          background: linear-gradient(to bottom, transparent 0%, $header-background-color 92%);
        }

        &__title {
          position: relative;
          display: block;
          margin-bottom: 4px;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          z-index: 1;
        }

        &__subtitle {
          @extend .banner-names__title;
          margin-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }
</style>