<template>
  <search-error
    v-if="isLoaded && !areasLive && isLiveRoute && !visibleAreas.length"
    style="margin: 1.25rem 0 0 2rem"
    :title="'Live'"
    :header="'No Results'"
    :problem-text="'Sorry, there are no live shows to display.'"
    :solution="'Please check back later.'"
  />
  <pv-scroll
    v-else
    :is-loaded="isLoaded"
    :is-areas-loading="isAreasLoading"
    :class="{ 'no-scroll': noScroll, 'with-live-area': currentLive }"
    class="h-100 mt-3"
    @bottom="bottom"
  >
    <transition-group
      name="stagged-area"
      :appear="true"
      appear-active-class="animated fadeInUp"
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
      tag="div"
      class="h-100 d-flex flex-column"
      @after-appear="onTransitionLeave"
      @enter="onTransitionEnter"
      @leave="onTransitionLeave"
    >
      <channel-profile
        v-if="profile"
        key="channelProfile"
        class="area-block custom_channel_profile"
        :area="profile"
        :badges="badges"
      />

      <channel-event
        v-if="profile"
        key="currentLive"
        class="area-block custom_channel_event"
        :area="currentLive"
      />

      <div
        v-for="(area, index) in _areas"
        :key="`key${index}`"
        :style="{ order: area.order }"
        :class="area && area.itype"
        @mouseenter="isTitle(area)"
      >
        <social-block
          v-if="area.aclass === 'section_social'"
          class="area-block"
          :area="area"
        />
        <segment-carousel
          v-else-if="area.aclass === 'section_internal' && page !== 'article'"
          :area="area"
          ordered
          @empty-segment="onSegmentEmpty"
        />

        <audio-player
          v-else-if="
            area.itype === 'section_album' || area.itype === 'section_playlist'
          "
          class="area-block"
          :album="area"
        />

        <promo
          v-else-if="area.aclass === 'section_promo'"
          class="area-block"
          :area="area"
        />

        <live-epg
          v-else-if="area.aclass === 'epg'"
          class="area-block"
          :area="area"
        />

        <!--        <live-player
                  v-else-if="area.aclass === 'live_player' && page === 'live'"
                  class="area-block"
                  :area="area"
                />-->

        <media-player
          v-else-if="
            area.aclass === 'video_flow' ||
              (area.aclass === 'video' && page === 'video')
          "
          class="area-block"
          :area="area"
          :extended="page === 'video'"
        />
        <vod
          v-else-if="area.aclass === 'video' && page === 'home'"
          class="area-block"
          :area="area"
        />

        <media-player
          v-if="
            [
              'item_mov_vod',
              'item_mov_vod_shoprz',
              'item_mov_episode',
            ].includes(area.itype)
          "
          class="area-block"
          :area="area"
          :shop-layouts="area.creator && area.creator.id ? area.creator : null"
          :is-one="true"
        />

        <segment-carousel
          v-else-if="isSegmentCarousel(area.itype)"
          :key="areaKey(area, index)"
          :area="area"
          ordered
          :reversed="area.itype === 'section_season'"
          :border="
            index !== _areas.length - 1 && area.itype !== 'section_season'
          "
          @empty-segment="onSegmentEmpty"
        />

        <category-title
          v-else-if="
            ['category_head', 'category_series_head'].includes(area.itype)
          "
          class="area-block"
          :area="area"
        />

        <live-epg
          v-else-if="area.itype === 'item_live'"
          class="area-block"
          :area="area"
        />
        <banner-carousel-embla
          v-else-if="area.itype === 'banner_group' && page === 'home'"
          :area="area"
          :is-show-player="page === 'home'"
          @empty-segment="onSegmentEmpty"
        >
          <template #mediaPlayer>
            <player-with-tabs
              v-if="page === 'home'"
              class="h-100 w-100"
              :tabs="[
                {
                  id: 'live',
                  title: 'Live',
                  link: '/on_air/',
                },
                {
                  id: 'vod',
                  title: 'Videos',
                  link: `/get_section/${videoSectionTabId}/`,
                },
              ]"
              :watch-lives="true"
              :shop-layouts="area.creator && area.creator.id ? area.creator : null"
            />
          </template>
        </banner-carousel-embla>

        <banner-carousel
          v-else-if="area.itype === 'banner_group'"
          :area="area"
          @empty-segment="onSegmentEmpty"
        >
        </banner-carousel>

        <app-area
          v-else-if="area.itype === 'item_app'"
          class="area-block"
          :area="area"
        />

        <directive-carousel
          v-else-if="['section_link_directives'].includes(area.itype)"
          class="area-block"
          :area="area"
          @empty-segment="onSegmentEmpty"
        />
      </div>
    </transition-group>
  </pv-scroll>
</template>

<script>
  import SocialBlock from 'src/components/social-block/social-block';
  import InternalCarousel from 'src/components/pro-carousel/internal-procarousel';
  import DirectiveCarousel from 'src/components/pro-carousel/directive-carousel';
  import ProCarousel from 'src/components/pro-carousel/pro-carousel';
  import Promo from 'src/components/promo/promo';
  import Vod from 'src/components/player/main/vod';
  import MediaPlayer from 'src/components/player/media/media-player';
  import LiveEpg from 'src/components/live-epg/live-epg';
  import ChannelProfile from 'src/components/channel-profile/channel-profile';
  import ChannelEvent from 'src/components/channel-profile/channel-event';
  import BlockChanels from '../block-chanels/block-chanels';
  import Backdrop from '../backdrop/backdrop';
  import AudioPlayer from 'src/components/player/audio-player/audio-player';
  import VideoPlayer from 'src/components/video-player.vue';
  import AppArea from 'src/components/area-app.vue';
  import CategoryTitle from 'src/components/category-title/category-title';
  import PvScroll from 'src/components/pv-scroll/scroll';
  import { AuthVariableMixin } from 'src/plugins/mixin';
  import SegmentCarousel from 'src/components/segment-carousel/segment-carousel';
  import BannerCarousel from 'src/components/banner-carousel/banner-carousel';
  import SearchError from '../searchError/searchError.vue';
  import { mapGetters } from 'vuex';
  import { amsClient } from '../../service/ams';
  import { debounce } from 'lodash/function';
  import rtm from '../../service/realTimeMessages';
  import PlayerWithTabs from '@/components/player/player-with-tabs/player-with-tabs.vue';
  import BannerCarouselEmbla from '@/components/banner-carousel/banner-carousel-embla.vue';

  export default {
    name: 'Areas',
    components: {
      BannerCarouselEmbla,
      PlayerWithTabs,
      SegmentCarousel,
      BlockChanels,
      MediaPlayer,
      SocialBlock,
      Promo,
      AudioPlayer,
      InternalCarousel,
      ProCarousel,
      Vod,
      LiveEpg,
      ChannelProfile,
      ChannelEvent,
      Backdrop,
      VideoPlayer,
      AppArea,
      CategoryTitle,
      DirectiveCarousel,
      PvScroll,
      BannerCarousel,
      SearchError,
    },
    mixins: [AuthVariableMixin],
    props: {
      page: {
        type: String,
        default: null,
        validator: function(page) {
          let types = [
            'video',
            'home',
            'article',
            'show',
            'channel',
            'live',
            'search',
            'shows-az',
            'dashboard',
            'section',
            'category',
          ];
          return types.indexOf(page) !== -1;
        },
      },
      areas: {
        type: Array,
        default: () => [],
      },
      scroll: {
        type: Boolean,
        default: false,
      },
      profile: {
        type: Object,
        default: null,
      },
      pageItem: {
        type: Object,
        default: null,
      },
      currentLive: {
        type: Object,
        default: undefined,
      },
      badges: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        handledAreas: [],
        visibleAreas: [],
        eventsSegment: null,
        upcomingSegment: null,
        eventsSegmentIndex: null,
        subscribers: [],
        COUNT_ITEMS: 4,
        index: 0,
        isLoaded: false,
        isAreasLoading: false,
        isAllItemsVisible: false,
        widthBlock: 0,
        sizeWidth: null,
      };
    },
    computed: {
      ...mapGetters({
        areasLive: 'dataAreasLive/areasLive',
      }),
      videoSectionTabId() {
        return process.env.VUE_APP_TAB_VOD_SECTION_ID;
      },
      isSegmentCarousel() {
        return (itype) => {
          return [
            'area_grid',
            'section',
            'section_filters',
            'section_shoprz',
            'section_filters_shoprz',
            'section_link_shoprz',
            'section_shopitem',
            'section_shopitem_shoprz',
            'category',
            'category_shoprz',
            'section_channels',
            'category_series',
            'section_link',
            'section_link_directives',
            'section_season',
          ].includes(itype);
        };
      },
      _areas() {
        if (_isPreviewMode()) {
          return this.visibleAreas.filter((area) => {
            return ['custom_channel_profile'].indexOf(area.itype) !== -1;
          });
        }

        let _areas = this.visibleAreas.filter((area) => !!area);

        const otherProducts = _areas.find(
          (segment) => segment?.title === 'Other Products',
        );

        if (otherProducts) {
          _areas = _areas.filter((a) => a?.title !== 'Other Products');
          _areas.push(this.generateOtherProductsSegment(otherProducts));
        }

        return _areas;
      },
      isActiveScroll() {
        return this.isActiveFeature('use-scroll-area');
      },
      noScroll() {
        return (!this.scroll && this.handledAreas.length < 2) || !this.scroll;
      },
      isLiveRoute() {
        return this.$route.path === '/screen/live/';
      },
    },
    watch: {
      areas: {
        handler() {
          this.index = 0;
          this.isLoaded = false;
          this.isAllItemsVisible = false;
          this.visibleAreas = [];
          this.handledAreas = [...this.areas];
          this.prepareAreas().then(() => {
            if (this.isActiveScroll) {
              this.addItemsByScroll();
            } else {
              this.pushNextItem();
            }
            // console.log(`[debug][afterPrepareAreas][watch]`, this.visibleAreas, this.index, this.isActiveScroll);
            this.isLoaded = true;
          });
        },
        immediate: true,
      },
    },
    created() {
      this.index = 0;
    },
    mounted() {
      console.timeEnd('AREAS');
      this.addUpdateEventsSubscriber();
    },
    beforeDestroy() {
      rtm.off(this.subscribers);
    },
    methods: {
      areaKey(area, index) {
        return (area.id || `areakey${index}`) + (area.preparedItems?.length || 0);
      },
      addUpdateEventsSubscriber() {
        this.subscribers.push(rtm.on(
          ['finished', 'on_air_now', 'started', 'failed', 'scheduled'],
          debounce((data) => {
            this.isLoaded = false;
            this.prepareAreas().then(() => {
              this.visibleAreas = this.handledAreas.slice(
                0,
                this.index,
              );
              this.isLoaded = true;
            });

          }, 1000),
        ));
      },
      // WEB2-3994
      async prepareAreas() {
        this.eventsSegment = this.eventsSegment || this.generateEventsSegment([], undefined, undefined);
        if (this.handledAreas.length && this.eventsSegment.linkOnAir === undefined) {
          this.handledAreas = this.handledAreas.filter((area, index) => {
            if (area.directives?.tag === 'on_air') {
              this.eventsSegment.linkOnAir = area.link;
              if (this.eventsSegmentIndex === null) {
                this.eventsSegmentIndex = index;
              }
            }
            return area.directives?.tag !== 'on_air';
          });
          if (this.eventsSegment.linkOnAir === undefined) {
            this.eventsSegment.linkOnAir = null;
          }
        }

        if (this.handledAreas.length && this.eventsSegment.linkComingSoon === undefined) {
          this.handledAreas = this.handledAreas.filter((area, index) => {
            if (area.directives?.tag === 'coming_soon' && area.directives?.itype === 'item') {
              this.eventsSegment.linkComingSoon = area.link;
              if (this.eventsSegmentIndex === null) {
                this.eventsSegmentIndex = index;
              }
              return false;
            }
            return true;
          });
          if (this.eventsSegment.linkComingSoon === undefined) {
            this.eventsSegment.linkComingSoon = null;
          }
        }

        let hasLinks = this.eventsSegment.linkOnAir || this.eventsSegment.linkComingSoon;

        if (hasLinks) {
          const [onAir, comingSoon] = await Promise.all([
            this.eventsSegment.linkOnAir && amsClient.callAms(`${this.eventsSegment.linkOnAir}${this.$eventsTimestamp ? `&t=${this.$eventsTimestamp}` : ''}`),
            this.eventsSegment.linkComingSoon && amsClient.callAms(`${this.eventsSegment.linkComingSoon}${this.$eventsTimestamp ? `&t=${this.$eventsTimestamp}` : ''}`),
          ]);
          const onAirItems = onAir?.items || [];
          const comingSoonItems = comingSoon?.items || [];
          if (onAirItems.length >= 3) {
            this.eventsSegment.title = 'Live Events';
          } else if (onAirItems.length && comingSoonItems.length) {
            this.eventsSegment.title = 'Live & Upcoming Events';
          } else if (onAirItems.length) {
            this.eventsSegment.title = 'Live Events';
          } else if (comingSoonItems.length) {
            this.eventsSegment.title = 'Upcoming Events';
          }

          if (onAirItems.length >= 3) {
            this.eventsSegment.preparedItems = onAirItems;
            this.upcomingSegment = this.generateEventsSegment(comingSoonItems, null, this.eventsSegment.linkComingSoon);
            this.upcomingSegment.title = 'Upcoming Events';
            this.upcomingSegment.id = 'upcoming_events';
          } else {
            this.eventsSegment.preparedItems = [...onAirItems, ...comingSoonItems];
            this.upcomingSegment = null;
          }

          const currentIndex = this.handledAreas.findIndex((area) => area.id === this.eventsSegment.id);
          if (currentIndex === -1) {
            if (this.eventsSegment.preparedItems.length) {
              this.handledAreas.splice(this.eventsSegmentIndex, 0, this.eventsSegment);
            }
          } else {
            if (!this.eventsSegment.preparedItems.length) {
              this.handledAreas.splice(currentIndex, 1);
            }
          }

          const currentUpcomingIndex = this.handledAreas.findIndex((area) => area.id === 'upcoming_events');
          if (this.upcomingSegment) {
            this.handledAreas.splice(this.eventsSegmentIndex + 1, 0, this.upcomingSegment);
          } else if (currentUpcomingIndex !== -1) {
            this.handledAreas.splice(currentUpcomingIndex, 1);
          }

          this.handledAreas = this.handledAreas.map((area) => {
            if (area.directives?.tag === 'coming_soon') {
              area.preparedItems = comingSoonItems;
            }
            if (area.directives?.tag === 'on_air') {
              area.preparedItems = onAirItems;
            }
            return area;
          });

          this.$store.commit('dataAreasLive/setItems', { type: 'comingSoonItems', items: onAirItems });
          this.$store.commit('dataAreasLive/setItems', { type: 'airItems', items: comingSoonItems });
        }
        /*

                this.handledAreas.forEach((area, index) => {
                  const tag = area.directives?.tag;
                  const itemIType = area.directives?.itype === 'item';
                  if (tag === 'on_air') {
                    onAirLink = `${area.link}${this.$eventsTimestamp ? `&t=${this.$eventsTimestamp}` : ''}`;
                  }
                  if (tag === 'coming_soon' && itemIType) {
                    comingSoonLink = `${area.link}${this.$eventsTimestamp ? `&t=${this.$eventsTimestamp}` : ''}`;
                  }

                  if ((tag === 'on_air' || tag === 'coming_soon') && eventsInsertIndex === null) {
                    eventsInsertIndex = index;
                  }
                });
                const [onAir, comingSoon] = await Promise.all([
                  onAirLink && amsClient.callAms(onAirLink),
                  comingSoonLink && amsClient.callAms(comingSoonLink),
                ]);

                if ((onAir?.items?.length || 0) <= 3 && (onAir?.items?.length || 0) > 0) {
                  const areas = this.handledAreas.filter((area) => area.directives?.tag !== 'coming_soon' && area.directives?.tag !== 'on_air');
                  this.eventsSegment = this.generateEventsSegment([...onAir.items, ...comingSoon.items], onAirLink, comingSoonLink);
                  areas.splice(eventsInsertIndex || 0, 0, this.eventsSegment);
                  this.handledAreas = areas;
                } else {
                  this.handledAreas = this.handledAreas.map((area) => {
                    if (area.directives?.tag === 'coming_soon') {
                      area.preparedItems = comingSoon?.items;
                    }
                    if (area.directives?.tag === 'on_air') {
                      area.preparedItems = onAir?.items;
                    }
                    return area;
                  });
                }*//*
        console.log(111111111, {
          onAir, comingSoon,
        }, this.handledAreas);
        // this.visibleAreas = [];
        this.$store.commit('dataAreasLive/setItems', { type: 'comingSoonItems', items: onAir?.items });
        this.$store.commit('dataAreasLive/setItems', { type: 'airItems', items: comingSoon?.items });*/
      },
      isTitle(itype) {
        this.$bus.$emit('isTitle', itype.title);
      },
      onTransitionEnter() {
        this.$root.inTransition = true;
      },
      onTransitionLeave() {
        this.$root.inTransition = false;
      },
      bottom() {
        if (this.isActiveScroll) {
          this.addItemsByScroll();
        } else {
          this.pushNextItem();
        }
      },
      addItemsByScroll() {
        if (this.handledAreas.length > 0 && !this.isAllItemsVisible && this.visibleAreas.length < this.handledAreas.length) {
          this.isAreasLoading = true;
          let counter = Math.floor(this.index / this.COUNT_ITEMS) + 1;
          this.index = this.COUNT_ITEMS * counter;
          let pushed = this.handledAreas.slice(
            this.index - this.COUNT_ITEMS,
            this.index,
          );

          pushed.forEach((element) => {
            if (!this.visibleAreas.includes(element)) {
              this.visibleAreas.push(element);
            }
          });

          if (this.visibleAreas.length >= this.handledAreas.length) {
            this.isAllItemsVisible = true;
          }
          setTimeout(() => {
            this.isAreasLoading = false;
          }, 5000);
        }
      },
      pushNextItem(isSinglePush = false) {
        if (this.handledAreas.length > 0 && !this.isAllItemsVisible) {
          this.isAreasLoading = true;
          this.visibleAreas.push(this.handledAreas[this.index]);
          this.index++;
          if (!isSinglePush) {
            if (this.index < this.handledAreas.length) {
              if (this.index < 4) {
                this.$nextTick(this.pushNextItem);
              } else {
                this.pushNextItem();
              }
            }
          }
          setTimeout(() => {
            this.isAreasLoading = false;
          }, 5000);
        }
      },
      onSegmentEmpty() {
        this.pushNextItem(true);
      },
      generateOtherProductsSegment(segment) {
        return {
          poster: null,
          backdrop: null,
          title: segment.title,
          link: segment.link,
          id: null,
          itype: 'section_shopitem_shoprz',
          n_col: 3,
          aspect_ratio: '1x1',
          dtype: 'carousel',
          item_image_shape: 'vertical',
          item_title_position: 'bottom',
          seo_metadata: {
            title: segment.title,
            page_link: null,
          },
          page: null,
        };
      },
      generateEventsSegment(items, onAirLink, comingSoonLink) {
        return {
          poster: null,
          backdrop: null,
          title: 'Live & Upcoming Events',
          link: 'all-events',
          linkOnAir: onAirLink,
          linkComingSoon: comingSoonLink,
          id: 'all-events',
          itype: 'section_shoprz',
          n_col: 3,
          aspect_ratio: '16x9',
          dtype: 'carousel',
          item_image_shape: 'horizontal',
          item_title_position: 'bottom',
          seo_metadata: {
            title: 'Events',
            page_link: null,
          },
          preparedItems: items,
          page: null,
        };
      },
    },
  };
</script>

<style lang="scss">
  @import './areas';

  .no-scroll {
    min-height: auto !important;
  }
</style>