<template>
  <a
    :href="item.link"
    target="_blank"
    class="banner-item"
    @click="onClick"
  >
    <div class="banner-preview">
      <img :src="normalizeImg(image, 'thumb', 'FHD', 'HD')">
    </div>
    <slot name="title" />
  </a>
</template>
<script>
  import normalizeImg from '@/filters/normalizeImg';

  export default {
    name: 'BannerLinkItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      isClickAllowed: {
        type: Function,
        default: () => true,
      },
    },
    computed: {
      link() {
        if (!this.item?.link) {
          return null;
        }

        const splitUrl = this.item.link.split(/^(http|https):\/\/.{1,}\/portal/);

        return splitUrl.length ? splitUrl[splitUrl.length - 1] : null;
      },
      isRouterLink() {
        return this.$router.getRoutes()
          .filter(route => route.path !== '*')
          .some(route => route?.regex?.test(this.link));
      },
      image() {
        return this.item?.banner;
      },
    },
    methods: {
      normalizeImg: normalizeImg,
      onClick() {

        if (!this.isClickAllowed()) {
          return;
        }
        const options = this.$stats.formatEventDate(this.item);
        this.$stats.send('object_click', { ...options, position: this.index });
      },
    },
  };
</script>
<style lang="scss" scoped></style>