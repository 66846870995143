<template>
  <div class="banner-item" @click="openProduct">
    <div class="banner-preview">
      <img :src="normalizeImg(image, 'thumb', 'FHD', 'HD')">
    </div>
    <slot name="title" />
  </div>
</template>
<script>
  import normalizeImg from '@/filters/normalizeImg';

  export default {
    name: 'BannerProductItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      isClickAllowed: {
        type: Function,
        default: () => true,
      },
    },
    computed: {
      image() {
        return this.item?.banner;
      },
    },
    methods: {
      normalizeImg: normalizeImg,
      openProduct() {
        if (!this.isClickAllowed()) {
          return;
        }
        const options = this.$stats.formatEventDate(this.item);
        this.$stats.send('object_click', { ...options, position: this.index });

        this.$bus.$emit('toggleProductPopup', { item: this.item });
      },
    },
  };
</script>
<style lang="scss" scoped>

</style>