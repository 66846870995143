<template>
  <pro-carousel
    v-if="!loading"
    class="internal-section-component"
    :area="area"
    :buh-context="stockType"
    :items-prop="items"
  />
</template>

<script>
  import ProCarousel from './pro-carousel';
  import { amsClient } from 'src/service/ams';
  import Auth from 'src/service/authService';

  export default {
    name: 'InternalSection',
    components: {
      ProCarousel,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loading: true,
        items: [],
      };
    },
    computed: {
      stockType() {
        if (this.tag === 'watchlist') {
          return 'recent';
        } else if (this.tag === 'favorite') {
          return 'favorites';
        } else
          return this.tag;

      },
      tag() {
        return this.area && this.area.directives && this.area.directives.tag ? this.area.directives.tag : null;
      },
    },
    created() {
      this.getData();
      this.$bus.$on('sync', this.onSync);
    },
    beforeDestroy() {

      this.$bus.$off('sync', this.onSync);
    },
    methods: {
      getData() {
        this.loading = true;
        if (Auth.isAuth() && !Auth.isGuest() && ['recent', 'favorites', 'watch_later'].indexOf(this.stockType) !== -1) {

          let call;
          if (this.stockType === 'favorites') {
            call = amsClient.stock.getFavorites(false, 0, 50);
          } else if (this.stockType === 'recent') {
            call = amsClient.stock.getRecent(false, 0, 50);
          } else if (this.stockType === 'watch_later') {
            call = amsClient.stock.getWatchLater(false, 0, 50);
          }
          call.then((items) => {
            this.items = items;
            if (!this.items.length) {
              this.$emit('empty-segment');
            }
            this.loading = false;
          }).catch((error) => {
            // console.log(error);
            this.items = [];
            this.$emit('empty-segment');
            this.loading = false;
          });
        } else {
          this.$nextTick(() => {
            this.items = [];
            this.$emit('empty-segment');
            this.loading = false;
          });
        }
      },
      //
      onSync() {
        this.getData();
      },
    },
  };
</script>

<style lang="scss">
</style>
