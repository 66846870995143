<template>
  <section class="banner">
    <swiper
      ref="mySwiper"
      class="h-100"
      :options="swiperOptions"
      @slideChangeTransitionEnd="onSlideChange"
    >
      <swiper-slide v-for="(slide, index) of carouselItems" :key="`${slide.iid}_${index}`">
        <component
          :is="getComponentType(slide)"
          :item="slide"
          :index="index"
          :current-index="currentIndex"
          @click.native="handleClickSlide(slide)"
          @runAutoPlay="toggleAutoPlay('run')"
          @pauseAutoPlay="toggleAutoPlay('pause')"
        >
          <template slot="title">
            <div v-if="slide.title || slide.subtitle" class="banner-names">
              <h2 v-if="slide.title" class="banner-names__title">
                {{ slide | contentLanguage('title') }}
              </h2>
              <p v-if="slide.subtitle" class="banner-names__subtitle">
                {{ slide | contentLanguage('subtitle') }}
              </p>
            </div>
          </template>
        </component>
      </swiper-slide>
    </swiper>
    <ul class="banner-pagination">
      <li
        v-for="(item, index) of carouselItems"
        :key="item.iid"
        :class="{ 'active': index === currentIndex }"
        @click="onPaginationClick(index)"
      />
    </ul>
  </section>
</template>
<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import Config from '@/service/config';
  import BannerLinkItem from './banner-link-item/banner-link-item';
  import BannerProductItem from './banner-product-item/banner-product-item';
  import BannerVideoItem from './banner-video-item/banner-video-item';
  import { debounce } from 'lodash';

  export default {
    name: 'BannerCarousel',
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
      isShowPlayer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        currentIndex: 0,
        swiperOptions: {
          loop: true,
          centeredSlides: true,
          spaceBetween: 20,
          slidesPerView: 1,
          autoplay: {
            delay: 5000,
            disableOnInteraction: true,
          },
          breakpoints: {
            1025: {
              slidesPerView: 2,
            },
          },
        },
      };
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper?.$swiper;
      },
      carouselItems() {
        return this.area.items || [];
      },
    },
    mounted() {
      if (!this.carouselItems.length) {
        this.$emit('empty-segment');
      }
    },
    methods: {
      onPaginationClick(index) {
        this.swiper?.slideTo(index + 2, 400);
      },
      onSlideChange() {
        this.currentIndex = this.swiper?.realIndex || 0;
      },
      getComponentType(item) {
        if (item.itype === 'item_mov_vod_shoprz' || item.itype === 'item_epg_shop_event') {
          return BannerVideoItem;
        }

        if (item.itype === 'shop_product') {
          return BannerProductItem;
        }

        return BannerLinkItem;
      },
      handleClickSlide(item) {
        this.$gtag('event', 'view_banner', {
          banner_name: item.title,
          banner_image: item.banner,
        });

        const id = item.b_id || item.id;
        let eventData = { banner_id: id };

        Config.set('temp.context', 'banner');
        // this.$stats.send(
        //   'banner_click',
        //   Object.assign({ item_id: id, banner_target: linkNormalisation(item) }, eventData),
        // );
        // assume that it is link to external site
        if (item.link && item.link.indexOf('http') !== -1) {
          // this.$stats.send(
          //   'external_application_opened',
          //   { ext_app_name: item.link },
          // );
        }
      },
      toggleAutoPlay(mode) {
        if (mode === 'pause') {
          this.swiper.autoplay.pause();
        }
        if (mode === 'run') {
          this.swiper.autoplay.run();
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .banner {
    position: relative;
    padding-top: 8px;
    padding-bottom: 72px;

    @media (max-width: 1024px) {
      padding-top: 0;
      padding-bottom: 40px;
    }

    &::v-deep {
      .banner-item {
        position: relative;
        display: block;
        background-color: $header-background-color;
        cursor: pointer;
      }

      .banner-preview {
        position: relative;
        padding-top: 42%;

        img,
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-position: center;
          background-size: cover;

          &.is-vertical {
            object-fit: contain;
            background-image: none !important;
            background-color: #000;
          }
        }

        video {
          width: calc(100% - 26%);

          @media (max-width: 680px) {
            width: 100%;
          }
        }
      }

      .banner-names {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 50px;
        padding: 24px;
        left: 0;
        right: 0;
        bottom: 0;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: block;
          background: linear-gradient(to bottom, transparent 0%, $header-background-color 92%);
        }

        &__title {
          position: relative;
          display: block;
          margin-bottom: 4px;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          z-index: 1;
        }

        &__subtitle {
          @extend .banner-names__title;
          margin-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }

  .banner-pagination {
    position: absolute;
    bottom: 48px;
    left: 50%;
    display: flex;
    gap: 8px;
    margin: 0;
    padding: 0;
    transform: translateX(-50%);

    @media (max-width: 1024px) {
      bottom: 55px;
      z-index: 2;
    }

    li {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #e2e5f1;
      cursor: pointer;

      &.active {
        background-color: $main-color;
      }
    }
  }
</style>